.progressBox {
  position: relative;
  display: flex;
  flex-direction: row;
  text-align: center;
  color: #000000;
  background: F5F5F5;
  border-radius: 10px;
}

.rangeBox {
  position: absolute;
  margin-top: -4px;
  width: 100%;
}
.slider {
  /* // position: absolute; */
  width: 100%;
  -webkit-appearance: none;
  background: transparent;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 4px;
  height: 30px;
  background: #000000;
  border-radius: 20%;
}

.slider::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 4px;
  height: 30px;
  background: #000000;
  border-radius: 5px;
}
