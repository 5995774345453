body {
  margin: 0;
  font-family: 'poppins', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
  /* height: 100%; */
  /* width: 100vw;  */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 0 5px #7ab6da;
  border-radius: 7px;
}
::-webkit-scrollbar-thumb {
  background: #959191;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}
::-webkit-scrollbar-thumb:hover {
  background: #7ab6da;
}

@media print {
  .no-print {
    display: none !important;
  }
}

.date-input {
  position: relative;
  height: 30px;
  width: 150px;
}

.time-component {
  /* position: relative; */
  padding: 7px 5px;
  border-radius: 8px;
}

.date-input[type='date']::-webkit-calendar-picker-indicator {
  background-position: right;
  background-size: auto;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: auto;
  border-radius: 10px;
}
/* 
.dialog {
  font-family: sans-serif !important;
  font-weight: 800;
  width: 100%;
} */
