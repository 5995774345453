@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap');

* {
  box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
  width: 1px;
}

.login {
  position: fixed;
  z-index: 3;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  width: 90%;
  /* height: 80%;
  overflow-y: scroll; */
  padding: 70px 30px 44px;
  border-radius: 1.25rem;
  background: #fff;
  text-align: center;
}

.login > img {
  width: 120px;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 20px;
  /* border: 1px dotted gray; */
}

.login > h2 {
  font-size: 26px;
  margin: 0 0 25px;
}

.login > h3 {
  color: rgba(0, 0, 0, 0.38);
  margin: 0 0 6px;
  font-weight: 500;
  font-size: 1rem;
}

.login-form {
  display: grid;
  gap: 30px;
  place-items: center;
  width: 100%;
  margin: 0;
}

.login-form > input,
.login-form > button,
.password-input {
  height: 56px;
  outline: none;
  padding: 0;
  font-family: inherit;
}

.login-form > input,
.password-input {
  width: 240px;
  background: transparent;
  border: 2px solid transparent;
  border-radius: 8px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  font-size: 14px;
  padding: 0;
  text-align: center;
  transition: all 0.3s;
}

.login-form > input:hover,
.password-input {
  border-bottom: 2px solid #303f9f;
}

.login-form > input:focus,
.password-input {
  border-bottom: 2px solid #1976d2;
}

.login-form > button {
  cursor: pointer;
  width: 100%;
  height: 56px;
  background: #303f9f;
  color: #f9f9f9;
  border: 0;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 2px;
  transition: all 0.3s;
}

.login-form > button:hover {
  background-color: #1976d2;
}

.login-form > a {
  color: #303f9f;
  font-size: 1rem;
  text-align: left;
  text-decoration: none;
  transition: all 0.3s;
}

.login-form > a:hover {
  color: #1976d2;
}

@media (width >= 450px) {
  .login {
    width: 380px;
  }
}

@media (width >= 500px) {
  body {
    padding: 0;
  }
}

@media (width >= 1200px) {
  .login {
    width: 25%;
  }
}

.password-input-container {
  position: relative;
}

.password-toggle-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
}
